import { EditorAppContext } from '../types';
import { TOKEN } from './constants';
import { openDashboardModal } from './panels';

export const showSSLEnableNotification = async ({
  editorSDK,
  translate,
  sentry,
}: EditorAppContext): Promise<void> => {
  const metaSiteId = await editorSDK.document.info.getMetaSiteId(TOKEN);
  sentry.captureMessage('SSL is not enabled notification'); // TODO: understand if this notification shows up for anyone

  await editorSDK.editor
    .showNotification(TOKEN, {
      message: translate('notificationSSL.title'),
      type: 'warning',
      link: { caption: translate('notificationSSL.link') },
    })
    .then((isClickedOnLink: boolean) => {
      if (isClickedOnLink) {
        openDashboardModal(
          editorSDK,
          `https://manage.wix.com/dashboard/${metaSiteId}/manage-website/ssl`, // TODO: broken link
        );
      }
    });
};

import type { PageRef, TransactionError } from '@wix/editor-platform-sdk-types';
import type { ComponentRef, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { EditorAppContext, HandleError } from '../types';
import { execEditorAction } from './execEditorAction';
import {
  getClassicControllerStructure,
  getResponsiveControllerStructure,
} from './structures/controller';
import { APP_ID, CONTROLLER_TYPE, TOKEN } from './constants';
import { EditorInteractionName } from '../enums/Interaction';

export async function getChildren(
  editorSDK: FlowEditorSDK,
  componentRef: ComponentRef,
): Promise<ComponentRef[]> {
  return editorSDK.components.getChildren(TOKEN, {
    componentRef,
  });
}

export async function getButtonText(
  editorSDK: FlowEditorSDK,
  componentRef: ComponentRef,
): Promise<{ label: string }> {
  const [button] = await getChildren(editorSDK, componentRef);
  return (await editorSDK.document.components.data.get(TOKEN, {
    componentRef: button,
  })) as Promise<{ label: string }>;
}

export async function updateButtonLabel(
  editorSDK: FlowEditorSDK,
  componentRef: ComponentRef,
  label: string,
  handleError: HandleError,
) {
  try {
    const [button] = await getChildren(editorSDK, componentRef);
    await execEditorAction({
      editorSDK,
      action: () =>
        editorSDK.document.transactions.runAndWaitForApproval(TOKEN, () => {
          return editorSDK.document.components.data.update(TOKEN, {
            componentRef: button,
            data: { label },
          });
        }),
    });
  } catch (e) {
    if (
      await editorSDK.document.transactions.isConflictError(
        TOKEN,
        e as TransactionError,
      )
    ) {
      handleError(e, 'Error in runAndWaitForApproval method (conflict).');
    } else {
      handleError(e, 'Error in updating button label.');
    }
  }
}

export const createSettingsPanelConfig = ({
  instance,
  helpId,
  componentRef,
  productId,
  buttonLabel,
  settingsTitle,
  startLoadTime,
  appContext,
}: {
  instance: string;
  helpId: string;
  componentRef: ComponentRef;
  productId: string;
  buttonLabel: string;
  settingsTitle: string;
  startLoadTime: number;
  appContext: EditorAppContext;
}) => {
  const isEditorX = appContext.isEditorX;

  return {
    componentRef,
    title: settingsTitle,
    width: 288,
    height: 546,
    helpId,
    url: `https://manage.wix.com/pay-button-settings?instance=${instance}`,
    initialData: {
      productId,
      buttonLabel,
      appDefinitionId: APP_ID,
      componentRef,
      startLoadTime,
      isEditorX,
    },
  };
};

export async function addController({
  editorSDK,
  isEditorX,
  pageRef,
}: {
  editorSDK: FlowEditorSDK;
  isEditorX: boolean;
  pageRef: PageRef;
}) {
  return editorSDK.components.add(TOKEN, {
    pageRef,
    componentDefinition: isEditorX
      ? getResponsiveControllerStructure()
      : getClassicControllerStructure(),
  });
}

export const isControllersExist = async (editorSDK: FlowEditorSDK) => {
  const controllers = await editorSDK.document.controllers.findAllByType(
    TOKEN,
    {
      controllerType: CONTROLLER_TYPE,
    },
  );
  return controllers.length > 0;
};

export const uninstallApp = async ({
  editorSDK,
  fedops,
  handleError,
}: EditorAppContext) => {
  fedops.interactionStarted(EditorInteractionName.UNINSTALL_APP);
  try {
    await editorSDK.document.application.uninstall(TOKEN, {
      openConfirmation: false,
    });
  } catch (e) {
    handleError(e, 'Error in uninstallApp');
    return;
  }
  fedops.interactionEnded(EditorInteractionName.UNINSTALL_APP);
};

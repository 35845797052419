import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { TOKEN } from './constants';

export const getProductIdsList = async (editorSDK: FlowEditorSDK) => {
  const listControllers = await editorSDK.controllers.listAllControllers(TOKEN);
  const productIds: string[] = [];
  for (const controller of listControllers) {
    const {
      config: { productId },
    } = await editorSDK.document.controllers.getData(TOKEN, controller);
    productIds.push(productId);
  }
  return productIds;
};

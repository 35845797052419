import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { TOKEN } from '../constants';

export async function openBusinessInfoPanel(editorSDK: FlowEditorSDK) {
  return openDashboardModal(editorSDK, 'business-info?referralInfo=pay-button');
}

export async function openCurrencyPanel(editorSDK: FlowEditorSDK) {
  return openDashboardModal(
    editorSDK,
    'settings/language-and-region?referralInfo=pay-button',
  );
}

export async function openConnectPaymentMethodsPanel(params: {
  editorSDK: FlowEditorSDK;
  startLoadTime: number;
}) {
  return openDashboardModal(
    params.editorSDK,
    `payments?referralInfo=pay-button&startLoadTime=${params.startLoadTime}`,
  );
}

export function openDashboardModal(
  editorSDK: FlowEditorSDK,
  url: string,
): Promise<void> {
  return editorSDK.editor.openDashboardPanel(TOKEN, {
    url,
    closeOtherPanels: true,
  });
}

import type { payButtonClickToOpenPaymentSettingsParams } from '@wix/bi-logger-payments-data/v2/types';
import { EditorAppContext } from '../types';
import { APP_ID, TOKEN } from './constants';

export const getBiLoggerParams = async (
  appContext: EditorAppContext,
): Promise<payButtonClickToOpenPaymentSettingsParams> => {
  const [msid, userGuid, app_instance_id] = await Promise.all([
    appContext.editorSDK.document.info.getMetaSiteId(TOKEN),
    appContext.editorSDK.document.info.getUserId(TOKEN),
    appContext.editorSDK.document.info.getAppInstanceId(TOKEN),
  ]);
  const editorType = appContext.isEditorX
    ? appContext.editorSubType || 'EditorX/Studio'
    : 'Classic';

  return {
    app_id: APP_ID,
    app_instance_id,
    msid,
    userGuid,
    editorType,
  };
};

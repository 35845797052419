import { ComponentRef } from '@wix/yoshi-flow-editor';
import { EditorInteractionName } from '../enums/Interaction';
import { EditorAppContext } from '../types';
import { addControllerAndConnectButton } from '../utils/addControllerAndConnectButton';
import { createProduct } from '../utils/api';
import { TOKEN, createDefaultProduct } from '../utils/constants';
import { overrideConnectionConfig } from '../utils/overrideConnectionConfig';

// NOTE: called while adding from App Market
export const addComponent = async ({
  editorSDK,
  fedops,
  isEditorX,
  handleError,
}: EditorAppContext) => {
  fedops.interactionStarted(EditorInteractionName.FIRST_INSTALL_APP_MARKET);
  const pageRef = await editorSDK.pages.getCurrent(TOKEN);
  await addControllerAndConnectButton({
    editorSDK,
    isEditorX,
    pageRef,
    handleError,
  });

  fedops.interactionEnded(EditorInteractionName.FIRST_INSTALL_APP_MARKET);
};

// NOTE: called after adding component to stage (from App Market OR Add Panel)
export const connectProductToComponent = async (
  componentRef: ComponentRef,
  { editorSDK, httpClient, fedops, translate, handleError }: EditorAppContext,
) => {
  fedops.interactionStarted(EditorInteractionName.FIRST_INSTALL_ADD_PANEL);
  try {
    const [currency, metaSiteId, instance] = await Promise.all([
      editorSDK.document.info.getCurrency(TOKEN),
      editorSDK.document.info.getMetaSiteId(TOKEN),
      editorSDK.info.getAppInstance(TOKEN),
    ]);

    const { productId } = await createProduct(
      httpClient,
      instance,
      createDefaultProduct(currency, translate('item.defaultName')),
    );

    await overrideConnectionConfig(
      editorSDK,
      componentRef,
      { productId, metaSiteId },
      handleError,
    );
  } catch (e) {
    handleError(e, 'Error while creating a product.');
  }

  fedops.interactionEnded(EditorInteractionName.FIRST_INSTALL_ADD_PANEL);
};

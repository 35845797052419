import { CompStructure } from '@wix/document-services-types';

export const classicButtonStructure: CompStructure = {
  componentType: 'wysiwyg.viewer.components.SiteButton',
  style: {
    type: 'TopLevelStyle',
    styleType: 'custom',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
    } as any,
    style: {
      properties: {
        'alpha-bg': '1',
        'alpha-bgh': '1',
        'alpha-brd': '1',
        'alpha-brdh': '0',
        'alpha-txt': '1',
        'alpha-txth': '1',
        bg: 'color_15',
        bgh: 'color_18',
        'boxShadowToggleOn-shd': 'false',
        brd: 'color_15',
        brdh: 'color_11',
        brw: '0',
        fnt: 'font_6',
        rd: '0px',
        shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
        txt: 'color_11',
        txth: 'color_11',
      },
      propertiesSource: {
        'alpha-bg': 'value',
        'alpha-bgh': 'value',
        'alpha-brd': 'value',
        'alpha-brdh': 'value',
        'alpha-txt': 'value',
        'alpha-txth': 'value',
        bg: 'theme',
        bgh: 'theme',
        'boxShadowToggleOn-shd': 'value',
        brd: 'theme',
        brdh: 'theme',
        brw: 'value',
        fnt: 'theme',
        rd: 'value',
        shd: 'value',
        txt: 'theme',
        txth: 'theme',
      },
      groups: {},
    },
    componentClassName: 'wysiwyg.viewer.components.SiteButton',
    skin: 'wysiwyg.viewer.skins.button.BasicButton',
    id: 'style-jktpvpis',
  },
  layout: {
    width: 142,
    height: 40,
  },
  type: 'Component',
  data: {
    label: 'Buy Now',
    type: 'LinkableButton',
    name: 'superPuperButton',
    id: 'button-buy',
    metaData: {
      isHidden: false,
      isPreset: true,
      schemaVersion: '1.0',
    },
  },
  props: {
    align: 'center',
    margin: 0,
    type: 'ButtonProperties',
    metaData: {
      isHidden: false,
      isPreset: true,
      schemaVersion: '1.0',
    },
  },
};

export const responsiveButtonStructure: CompStructure = {
  componentType: 'wysiwyg.viewer.components.SiteButton',
  style: {
    type: 'ComponentStyle',
    styleType: 'custom',
    skin: 'wysiwyg.viewer.skins.button.WrappingButton',
    componentClassName: 'wysiwyg.viewer.components.SiteButton',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: undefined as any,
    },
    style: {
      propertiesOverride: {
        fnt: {
          fontSize: '16px',
        },
      },
      properties: {
        'alpha-txth': '1',
        bgh: 'color_18',
        shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
        rd: '999px 999px 999px 999px',
        'alpha-brdh': '0',
        txth: 'color_11',
        'alpha-brd': '1',
        'alpha-bg': '1',
        verticalPadding: '0',
        bg: 'color_15',
        txt: 'color_11',
        'alpha-bgh': '1',
        brw: '0',
        fnt: 'font_8',
        brd: 'color_15',
        'boxShadowToggleOn-shd': 'false',
        horizontalPadding: '0',
        'alpha-txt': '1',
        brdh: '#323232',
      },
      propertiesSource: {
        'alpha-txth': 'value',
        bgh: 'theme',
        shd: 'value',
        rd: 'value',
        'alpha-brdh': 'value',
        txth: 'theme',
        'alpha-brd': 'value',
        'alpha-bg': 'value',
        verticalPadding: 'value',
        bg: 'theme',
        txt: 'theme',
        'alpha-bgh': 'value',
        brw: 'value',
        fnt: 'theme',
        brd: 'theme',
        'boxShadowToggleOn-shd': 'value',
        horizontalPadding: 'value',
        'alpha-txt': 'value',
        brdh: 'value',
      },
      groups: {},
    },
  },
  layout: {
    fixedPosition: false,
    rotationInDegrees: 0,
    scale: 1,
    width: 142,
    height: 40,
  },
  type: 'Component',
  data: {
    label: 'Buy Now',
    type: 'LinkableButton',
    name: 'superPuperButton',
    id: 'button-buy',
    metaData: {
      isHidden: false,
      isPreset: true,
      schemaVersion: '1.0',
    },
  },
  props: {
    align: 'center',
    margin: 0,
    type: 'ButtonProperties',
    metaData: {
      isHidden: false,
      isPreset: true,
      schemaVersion: '1.0',
    },
  },
  layouts: {
    type: 'SingleLayoutData',
    componentLayout: {
      hidden: false,
      type: 'ComponentLayout',
      width: { type: 'percentage', value: 12 },
    },
    itemLayout: {
      type: 'GridItemLayout',
      alignSelf: 'center',
      justifySelf: 'center',
      gridArea: { rowStart: 1, rowEnd: 2, columnStart: 1, columnEnd: 2 },
      margins: {
        bottom: { type: 'percentage', value: 0 },
        left: { type: 'percentage', value: 0 },
        right: { type: 'percentage', value: 0 },
        top: { type: 'percentage', value: 0 },
      },
      id: undefined as any,
    },
    containerLayout: {} as any,
  },
};

export const buttonRole = 'PayButton';

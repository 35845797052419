import type { ComponentRef, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { getChildren } from './index';
import { TOKEN } from './constants';

export const connect = ({
  editorSDK,
  controllerRef,
  connectToRef,
  role,
}: {
  editorSDK: FlowEditorSDK;
  controllerRef: ComponentRef;
  connectToRef: ComponentRef;
  role: string;
}) => {
  return editorSDK.controllers.connect(TOKEN, {
    connectToRef,
    controllerRef,
    role,
    isPrimary: true,
  });
};
export async function connectButton(
  editorSDK: FlowEditorSDK,
  controllerRef: ComponentRef,
  buttonRole: string,
) {
  const [button] = await getChildren(editorSDK, controllerRef);
  return connect({
    editorSDK,
    controllerRef,
    connectToRef: button,
    role: buttonRole,
  });
}

import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { TransactionError } from '@wix/platform-editor-sdk';
import { sleep } from './sleep';
import { TOKEN } from './constants';

interface ExecEditorActionParams {
  editorSDK: FlowEditorSDK;
  action(): Promise<any>;
  maxRetries?: number;
}

export const execEditorAction = async <T extends any>(
  params: ExecEditorActionParams,
): Promise<T> => {
  const { editorSDK, action, maxRetries = 2 } = params;

  let retries: number = 0;
  let lastConflictError;

  do {
    try {
      return await action();
    } catch (e) {
      const isConflictError =
        await editorSDK.document.transactions.isConflictError(
          TOKEN,
          e as TransactionError,
        );
      if (!isConflictError) {
        throw e;
      }

      lastConflictError = e;
      retries += 1;
      await sleep(100);
    }
  } while (retries <= maxRetries);

  throw lastConflictError;
};

import { AppManifestBuilder } from '@wix/yoshi-flow-editor';
import { EditorEvent } from '../enums/editorEvents';
import { EditorAppContext } from '../types';
import { CONTROLLER_TYPE } from '../utils/constants';

export const getAppManifest = async (
  appManifestBuilder: AppManifestBuilder,
  { translate, isEditorX }: EditorAppContext,
) => {
  return appManifestBuilder
    .withJsonManifest({
      controllersStageData: {
        [CONTROLLER_TYPE]: {
          default: {
            gfpp: {
              desktop: {
                mainAction1: {
                  actionId: EditorEvent.OpenPaymentSettings,
                  label: translate('buttonGFPP.mainAction1'),
                },
                mainAction2: {
                  actionId: EditorEvent.ConnectPaymentMethods,
                  label: translate('buttonGFPP.mainAction2'),
                },
                iconButtons: {
                  link: 'HIDE',
                  design: isEditorX ? 'HIDE' : 'DEFAULT',
                },
                helpId: 'ff0c64a8-98f0-470a-9135-622f1504de83',
              },
            },
            behavior: {
              rotatable: false,
            },
          },
        },
      },
    })
    .build();
};

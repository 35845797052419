import { addController } from './index';
import { connectButton } from './connect';
import { execEditorAction } from './execEditorAction';
import type { ComponentRef, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { TOKEN, buttonRole } from './constants';
import type { PageRef, TransactionError } from '@wix/editor-platform-sdk-types';
import { HandleError } from '../types';

export const addControllerAndConnectButton = async ({
  editorSDK,
  pageRef,
  isEditorX,
  handleError,
}: {
  editorSDK: FlowEditorSDK;
  pageRef: PageRef;
  isEditorX: boolean;
  handleError: HandleError;
}): Promise<ComponentRef> => {
  try {
    return await execEditorAction({
      editorSDK,
      action: () =>
        editorSDK.document.transactions.runAndWaitForApproval(
          TOKEN,
          async (): Promise<ComponentRef> => {
            const controllerRef = await addController({
              editorSDK,
              isEditorX,
              pageRef,
            });
            await connectButton(editorSDK, controllerRef, buttonRole);
            return controllerRef;
          },
        ),
    });
  } catch (e) {
    if (
      await editorSDK.document.transactions.isConflictError(
        TOKEN,
        e as TransactionError,
      )
    ) {
      handleError(e, 'Error in runAndWaitForApproval method (conflict).');
    } else {
      handleError(e, 'Error in adding controller and connecting a button.');
    }
  }

  throw new Error('Error at addControllerAndConnectButton');
};

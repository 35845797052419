import { CompStructure } from '@wix/document-services-types';
import { APP_ID, CONTROLLER_TYPE } from '../constants';
import { responsiveButtonStructure, classicButtonStructure } from './button';

const getResponsiveControllerStructure = (): CompStructure => {
  return {
    componentType: 'platform.components.AppWidget',
    data: {
      controllerType: CONTROLLER_TYPE,
      name: 'WixPay',
      applicationId: APP_ID,
    },
    layout: {
      width: 320,
      height: 60,
    },
    components: [responsiveButtonStructure],
    layouts: {
      type: 'SingleLayoutData',
      componentLayout: {
        hidden: false,
        type: 'ComponentLayout',
        height: {
          type: 'px',
          value: 60,
        },
        width: {
          type: 'px',
          value: 320,
        },
      },
      itemLayout: {
        type: 'GridItemLayout',
        alignSelf: 'center',
        justifySelf: 'center',
        gridArea: { rowStart: 1, rowEnd: 2, columnStart: 1, columnEnd: 2 },
        margins: {
          bottom: { type: 'percentage', value: 0 },
          left: { type: 'percentage', value: 0 },
          right: { type: 'percentage', value: 0 },
          top: { type: 'percentage', value: 0 },
        },
        id: undefined as any,
      },
      containerLayout: {} as any,
    },
  };
};

const getClassicControllerStructure = (): CompStructure => {
  return {
    componentType: 'platform.components.AppWidget',
    data: {
      controllerType: CONTROLLER_TYPE,
      name: 'WixPay',
      applicationId: APP_ID,
    },
    layout: {
      width: 320,
      height: 60,
    },
    components: [classicButtonStructure],
  };
};

export { getResponsiveControllerStructure, getClassicControllerStructure };

import type { EditorReadyOptions } from '@wix/editor-platform-sdk-types';
import applicationConfig from '../../.application.json';

export interface CustomSentryOptions {
  firstInstall: boolean;
  isEditorX: boolean;
  metaSiteId?: string;
}

export function createSentryClient(
  monitoring: EditorReadyOptions['monitoring'],
  options: CustomSentryOptions,
) {
  const { sentry } = applicationConfig;
  const { firstInstall, metaSiteId, isEditorX } = options;

  return monitoring.createSentryMonitorForApp(sentry.DSN, {
    release: sentry.projectName,
    enabled: process.env.NODE_ENV === 'production',
    dataCallback: () => {},
    tags: {
      firstInstall,
      msid: metaSiteId,
      isInEditorX: isEditorX,
    },
  });
}

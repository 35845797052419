import type { ComponentRef, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import type { TransactionError } from '@wix/editor-platform-sdk-types';
import type { ConnectionConfig, HandleError } from '../types';
import { execEditorAction } from './execEditorAction';
import { TOKEN } from './constants';

export const overrideConnectionConfig = async (
  editorSDK: FlowEditorSDK,
  controllerRef: ComponentRef,
  connectionConfig: ConnectionConfig,
  handleError: HandleError,
) => {
  try {
    const { config } = await editorSDK.document.controllers.getData(TOKEN, {
      controllerRef,
    });
    await execEditorAction({
      editorSDK,
      action: () =>
        editorSDK.document.transactions.runAndWaitForApproval(TOKEN, () => {
          return editorSDK.document.controllers.saveConfiguration(TOKEN, {
            controllerRef,
            config: {
              ...config,
              ...connectionConfig,
            },
          });
        }),
    });
  } catch (e) {
    if (
      await editorSDK.document.transactions.isConflictError(
        TOKEN,
        e as TransactionError,
      )
    ) {
      handleError(e, 'Error in runAndWaitForApproval method (conflict).');
    } else {
      handleError(e, 'Error in saving configuration.');
    }
  }
};

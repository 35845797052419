import { ComponentRef } from '@wix/yoshi-flow-editor';
import { EditorAppContext } from '../types';
import { createProduct, getProduct } from './api';
import { overrideConnectionConfig } from './overrideConnectionConfig';
import { TOKEN } from './constants';

export const redefineButtonProductId = async ({
  appContext,
  savedProductId,
  controllerRef,
}: {
  appContext: EditorAppContext;
  savedProductId: string;
  controllerRef: ComponentRef;
}): Promise<string> => {
  const { editorSDK } = appContext;
  const [listControllers, instance] = await Promise.all([
    editorSDK.controllers.listAllControllers(TOKEN),
    editorSDK.info.getAppInstance(TOKEN),
  ]);
  const productIds: string[] = [];
  for (const controller of listControllers) {
    const {
      config: { productId },
    } = await editorSDK.document.controllers.getData(TOKEN, controller);
    productIds.push(productId);
  }

  if (productIds.filter((id) => savedProductId === id).length > 1) {
    savedProductId = await updateProduct({
      appContext,
      instance,
      controllerRef,
      savedProductId,
    });
  }
  return savedProductId;
};

const updateProduct = async ({
  appContext: { editorSDK, httpClient, handleError },
  instance,
  savedProductId,
  controllerRef,
}: {
  appContext: EditorAppContext;
  instance: string;
  savedProductId: string;
  controllerRef: ComponentRef;
}) => {
  const productData = await getProduct(httpClient, instance, savedProductId);
  const { productId: newProductId } = await createProduct(
    httpClient,
    instance,
    productData,
  );
  await overrideConnectionConfig(
    editorSDK,
    controllerRef,
    { productId: newProductId },
    handleError,
  );
  return newProductId!;
};
